import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import { slide as Menu } from "react-burger-menu"
import ButtonLink from "./button-link"
import Image from "../image"
import {
  mediaPropTypes,
  linkPropTypes,
  buttonLinkPropTypes,
} from "@/utils/types"
import CustomLink from "./custom-link"
import { localizePath } from "@/utils/localize"

const Navbar = ({ navbar, pageContext, footer }) => {
  return (
    <header className="header">
      {/* The actual navbar */}
      <div className="wrapper">
        <nav className="navbar">
          <div className="navbar_left">
            {/* Content aligned to the left */}
            <Link
              to={localizePath({ ...pageContext, isPreview: false, slug: "" })}
            >
              <Image
                placeholder="Xpensme"
                media={navbar.logo}
                className="header_logo"
              />
            </Link>
          </div>
          {/* List of links on desktop */}
          <div className="navbar_right">
            <ul className="navbar_links">
              {navbar.links.map(navLink => (
                <li key={navLink.id} className="navbar_links_item">
                  <CustomLink
                    link={{
                      ...navLink,
                      url: `${localizePath({
                        ...pageContext,
                        isPreview: false,
                        // Remove the '/'
                        slug: navLink.url.startsWith("/")
                          ? navLink.url.slice(1)
                          : navLink.url,
                      })}`,
                    }}
                    className="navbar_links_link"
                    activeClassName="navbar_links_link__active"
                  >
                    {navLink.text}
                  </CustomLink>
                </li>
              ))}
            </ul>

            {navbar.buttons && (
              <div className="navbar_buttons">
                {navbar.buttons.map(button => {
                  return <ButtonLink key={button.id} button={button} />
                })}
              </div>
            )}
            <div className="burgerMenuWrapper">
              <Menu>
                <div className="LinksContainer">
                  <Link
                    to={localizePath({
                      ...pageContext,
                      isPreview: false,
                      slug: "",
                    })}
                    className="logoWrapper"
                  >
                    <Image
                      placeholder="Xpensme"
                      media={navbar.logo}
                      className="header_logo"
                    />
                  </Link>
                  {navbar.links.map(navLink => (
                    <div className="linkWrapper">
                      <CustomLink
                        link={{
                          ...navLink,
                          url: `${localizePath({
                            ...pageContext,
                            isPreview: false,
                            // Remove the '/'
                            slug: navLink.url.startsWith("/")
                              ? navLink.url.slice(1)
                              : navLink.url,
                          })}`,
                        }}
                        className="navbar_links_link"
                        activeClassName="navbar_links_link__active"
                      >
                        {navLink.text}
                      </CustomLink>
                    </div>
                  ))}
                  {footer.links.map(navLink => (
                    <div className="linkWrapper">
                      <CustomLink
                        link={{
                          ...navLink,
                          url: `${localizePath({
                            ...pageContext,
                            isPreview: false,
                            // Remove the '/'
                            slug: navLink.url.startsWith("/")
                              ? navLink.url.slice(1)
                              : navLink.url,
                          })}`,
                        }}
                        className="navbar_links_link"
                        activeClassName="navbar_links_link__active"
                      >
                        {navLink.text}
                      </CustomLink>
                    </div>
                  ))}
                </div>
                {navbar.buttons && (
                  <div className="burgerMenu_navbar_buttons">
                    {navbar.buttons.map(button => {
                      return <ButtonLink key={button.id} button={button} />
                    })}
                  </div>
                )}
              </Menu>
            </div>
          </div>
        </nav>
      </div>
    </header>
  )
}

Navbar.propTypes = {
  navbar: PropTypes.shape({
    logo: mediaPropTypes,
    links: PropTypes.arrayOf(linkPropTypes),
    button: buttonLinkPropTypes,
  }),
}

export default Navbar
