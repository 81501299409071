import React from "react"

const TrustBox = () => {
  // Create a reference to the <div> element which will represent the TrustBox
  const ref = React.useRef(null)
  React.useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true)
    }
  }, [])
  return (
    <div
      ref={ref}
      class="trustpilot-widget"
      data-locale="en-NZ"
      data-template-id="5419b6a8b0d04a076446a9ad"
      data-businessunit-id="637d4df94cfb29693de8c56c"
      data-theme="dark"
      data-min-review-count="20"
      data-without-reviews-preferred-string-id="1"
      data-style-width="110px"
    >
      <a
        href="https://nz.trustpilot.com/review/xpensme.io"
        target="_blank"
        rel="noopener"
      >
        Trustpilot
      </a>
    </div>
  )
}
export default TrustBox
