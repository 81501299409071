import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import { linkPropTypes } from "@/utils/types"

const CustomLink = ({
  link,
  children,
  className = "",
  activeClassName = "",
}) => {
  const isInternalLink = (link.url || '#').startsWith("/")

  // For internal links, use the Next.js Link component
  if (isInternalLink) {
    return (
      <Link
        to={link.url}
        className={className}
        activeClassName={activeClassName}
      >
        {children}
      </Link>
    )
  }

  // Plain <a> tags for external links
  return (
    <a
      href={link.url}
      // Change target and rel attributes is newTab is turned on
      target={link.newTab ? "_blank" : "_self"}
      rel="noreferrer"
      className={className}
    >
      {children}
    </a>
  )
}

CustomLink.propTypes = {
  link: linkPropTypes,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  activeClassName: PropTypes.string,
}

export default CustomLink
