import React from "react"
import classNames from "classnames"
import { buttonLinkPropTypes } from "@/utils/types"
import CustomLink from "./custom-link"

const ButtonLink = ({ button }) => {
  return (
    <CustomLink link={button}>
      <div className={classNames("button-link", button.type === 'secondary' && 'button-link__inverted')}>{button.text}</div>
    </CustomLink>
  )
}

ButtonLink.propTypes = {
  button: buttonLinkPropTypes,
}

export default ButtonLink
