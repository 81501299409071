import React from "react"
import Navbar from "./elements/navbar"
import Footer from "./elements/footer"

const Layout = ({ children, global, pageContext }) => {
  const { navbar, footer } = global

  return (
    <>
      <Navbar navbar={navbar} pageContext={pageContext} footer={footer} />
      <div className="content">{children}</div>
      <Footer footer={footer} pageContext={pageContext} />
    </>
  )
}

export default Layout
