import React from "react"
import { Link } from "gatsby"
import { localizePath } from "@/utils/localize"
import Image from "../image"
import CustomLink from "./custom-link"
import TrustBox from "./trustbox"

const Footer = ({ footer, pageContext }) => {
  return (
    <footer className="footer">
      <div className="wrapper">
        <div className="footer_top">
          <div className="footer_logo">
            {footer.logo && (
              <Link
                to={localizePath({
                  ...pageContext,
                  isPreview: false,
                  slug: "",
                })}
              >
                <Image media={footer.logo} className="footer_logo_image" />
              </Link>
            )}
          </div>
          {footer.socials && (
            <ul className="footer_socials">
              {footer.socials.map(social => (
                <li className="footer_socials_item">
                  <Link key={social.id} to={social.url} target="_blank">
                    <Image
                      media={social.icon}
                      className="footer_socials_image"
                    />
                  </Link>
                </li>
              ))}
            </ul>
          )}
          <div className="footer_trustbox">
            <TrustBox />
          </div>
          <nav className="footer_nav">
            <ul className="footer_nav_list">
              {footer.links.map(link => (
                <li key={link.id} className="footer_nav_list_item">
                  <CustomLink
                    link={{
                      ...link,
                      url: `${localizePath({
                        ...pageContext,
                        isPreview: false,
                        // Remove the '/'
                        slug: link.url.startsWith("/")
                          ? link.url.slice(1)
                          : link.url,
                      })}`,
                    }}
                    className="footer_nav_list_link"
                  >
                    {link.text}
                  </CustomLink>
                </li>
              ))}
            </ul>
          </nav>
        </div>

        <div className="footer_copyright">
          © Copyright CardWorks, Inc. {new Date().getFullYear()}
        </div>
      </div>
    </footer>
  )
}

export default Footer
